<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="container">
      <div class="videos-container">
        <h1 class="text-center">MSM Tutorial Videos</h1>
        <div class="video-container">
          <h3>Mane Street Market overview</h3>
          <iframe src="https://www.youtube.com/embed/XZsgWYYQ6go" allowfullscreen></iframe>
        </div>
        <div class="video-container">
          <h3>Mane Street Market ad packages</h3>
          <iframe src="https://www.youtube.com/embed/P9Z2rskLkKI" allowfullscreen></iframe>
        </div>
        <div class="video-container">
          <h3>How to list a horse on Mane Street Market</h3>
          <iframe src="https://www.youtube.com/embed/zcjzi4DZ6m8?si=ZIOQTr5NofxbfIke" allowfullscreen></iframe>
        </div>
      </div>
      <hr>
      <h1 class="text-center" style="margin-top: 24px;">FAQ</h1>
      <div class="row" style="margin-top: 24px;">
        <div class="col">
          <select class="form-control" v-model="selected_question">
            <option :value="question.id" v-for="question in questions" :key="question.id">{{question.question}}</option>
          </select>
          <div id="question_1" class="question" style="display: block;">
            <h1>How do I download the mobile app?</h1>
            <p>You can download the mobile app on your phone by accessing the Apple App Store or the Google Play store.</p>
            <p>Search for Mane Street Market app</p>
          </div>
          <div id="question_2" class="question">
            <h1>Is there a charge to search for horses or service providers on the MSM website or mobile app?</h1>
            <p>There is no charge to download and use the MSM website or mobile app to search for horses for sale/ lease or search for equestrian service providers.</p>
          </div>
          <div id="question_3" class="question">
            <h1>Why should I create an account or sign in?</h1>
            <p>Creating an account allows you do a number of things including:</p>
            <p>a.	Save searches and search filters</p>
            <p>b.	Receive notifications from MSM when new horses are listed and other events occur that you may be interesting in knowing</p>
            <p>c.	List a horse for sale or list your equestrian service</p>
          </div>
          <div id="question_4" class="question">
            <h1>How do I create an account and profile?</h1>
            <p>On the home page of website and mobile app, click on Create Account and follow the prompts.  	On mobile app, you can also click on Profile which can be found along bottom of each page.</p>
            <p>To edit your profile, on website click on Profile icon in upper right hand of corner.  On mobile 	app, click on Profile found on bottom of each screen.  And the click on Edit Profile.</p>
          </div>
          <div id="question_5" class="question">
            <h1>What if I forgot my password or want to reset my password?</h1>
            <p>If you forgot your password, click on Sign In and then click on Forgot Your Password? and we’ll send you a link to reset your password.</p>
            <p>To reset your password click on Profile icon (if not signed in, sign in first) and in your profile click on Reset My Password and follow the prompts.</p>
          </div>
          <div id="question_6" class="question">
            <h1>How do I search for horses for sale?</h1>
            <p>a.	On the website or mobile app home page, click on Horses for Sale.</p>
            <p>b.	Select your search area choice.  If you choose “Current Location,” you must allow MSM to access your location in order for this method to work.</p>
            <p>c.	Click on “See List” to see horses for sale in list form or click on “Filters” to refine your search.</p>
            <p>d.	After clicking on “See List,” click on a horse listing to see more details.</p>
            <p>e.	After clicking on Filters, select the horse attributes that you are seeking and click “Apply Filters.”  If you would like to be notified when new horses are listed that meet your search criteria, click Allow Notifications.</p>
            <p>f.	To see the horses that meet your criteria on a map, click on “See Map.”</p>
            <p>g.	To save a horse to your saved searches or to share with a friend, first go to the detail screen for that horse and then click “Save to My Searches” or click on the Share icon.</p>
            <p>h.	To contact seller regarding a horse for sale, click on “Contact Seller.”</p>
          </div>
          <div id="question_7" class="question">
            <h1>How do I search for Equestrian Service Providers?</h1>
            <p>a.	On the website or mobile app home page, click on <router-link to="/search-service-providers?type=Any">Service Providers</router-link>.</p>
            <p>b.	Select you search area choice.  If you choose “Current Location,” you must allow MSM to access your location in order for this method to work.</p>
            <p>c.	Scroll through the list of Service Providers and click on the service provider type that you are seeking.</p>
            <p>d.	Service providers of the type you selected will appear in a list.  Click on a service provider listing to see more details.</p>
            <p>e.	To save a service provider to your saved searches or to share with a friend, click on “Save to My Searches” or click on the Share icon.</p>
            <p>f.	To contact the service provider, click on “Contact Service Provider.”</p>
          </div>
          <div id="question_8" class="question">
            <h1>My search is producing no results, how do I reset search filters?</h1>
            <p>You can reset filters by going back into the Filters screen and de-selecting filters and/or adding new filters.  Then, click Apply Filters.</p>
            <p>You may also want to Search “All Locations” to see there are any horses for sale that have you filtered attributes.</p>
          </div>
          <div id="question_9" class="question">
            <h1>How do I save a horse or service provider to my profile or share horses or service providers that I like with friends?</h1>
            <p>To save a horse or a service provider to your saved searches or to share with a friend, first go to the detail screen for that horse or service provider and then click “Save to My Searches” or click on the Share icon.</p>
            <p>You will need to have an account/profile with MSM in order to save horses or service providers.</p>
          </div>
          <div id="question_10" class="question">
            <h1>How do I list a horse or equestrian service?</h1>
            <p>a.	To list a horse for sale, start on the Home page and click on Horses for Sale.  On the Horses for Sale screen, click on List a Horse for Sale or Lease and follow the prompts.</p>
            <p>b.	To list your equestrian service, start on the Home page and click on Equestrian Service Providers.  On the Service Providers screen, click on Sign up as a Service Provider and follow the prompts.</p>
          </div>
          <div id="question_11" class="question">
            <h1>What kind of notifications does MSM send?</h1>
            <p>MSM sends a notification when a new horse is listed that meets a user’s search filter criteria, when a horse is sold that a user had saved as a favorite.</p>
            <p>Other general notifications may include the introduction of new features on MSM mobile app or website.</p>
            <p>Users can turn on or off specific notification types by accessing Profile page and selecting Notifications.</p>
          </div>
          <div id="question_12" class="question">
            <h1>How do I turn on or off notification from MSM?</h1>
            <p>Access your Profile by clicking on Profile after signing in and then select Notifications.  You can turn on or off specific notification types.</p>
          </div>
          <div id="question_13" class="question">
            <h1>How much does it cost to advertise my horse for sale or my equestrian service?</h1>
            <p>On the bottom of the Home page is a Listing/Advertising Pricing and Plans link.  Click on that link 	and it will take you to a screen that provides details on plans and pricing.</p>
          </div>
          <div id="question_14" class="question">
            <h1>What are the benefits of listing/advertising on MSM?</h1>
            <p>On the bottom of the Home page is a Benefits of MSM link.  Click on that link and it will take you to a screen that lists several benefits of listing and advertising with MSM.</p>
          </div>
          <div id="question_15" class="question">
            <h1>How do I delete/deactivate my profile?</h1>
            <p>After signing in to your account, access your Profile by clicking Profile in upper right hand corner 	of website screen or Profile along the bottom of mobile app screen.  On the Profile screen select 	Edit Profile and then select Delete Profile.</p>
          </div>
          <div id="question_16" class="question">
            <h1>How do I save a filter?</h1>
            <p>When searching for horses, go onto the Filters screen by clicking on "Filters" on the search view, then scroll down and click on "Save & Apply Filters". Enter a name for the filter and hit "Save".</p>
            <p>Another prompt will then appear and you will have the option of being notified by email when a new horse is listed that matches your saved filter. The email will be sent to the email address you signed up with.</p>
            <p>Note: You have to have an account and be signed in to access this feature.</p>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import $ from 'jquery';
import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';

export default {
  components: {BaseLayout, SubHeader},
  data() {
    return {
      selected_question: 'question_1',
      questions: [
        {id: 'question_1', question: 'How do I download the mobile app?'},
        {id: 'question_2', question: 'Is there a charge to search for horses or service providers on the MSM website or mobile app?'},
        {id: 'question_3', question: 'Why should I create an account or sign in?'},
        {id: 'question_4', question: 'How do I create an account and profile?'},
        {id: 'question_5', question: 'What if I forgot my password or want to reset my password?'},
        {id: 'question_6', question: 'How do I search for horses for sale?'},
        {id: 'question_7', question: 'How do I search for Equestrian Service Providers?'},
        {id: 'question_8', question: 'My search is producing no results, how do I reset search filters?'},
        {id: 'question_9', question: 'How do I save a horse or service provider to my profile or share horses or service providers that I like with friends?'},
        {id: 'question_10', question: 'How do I list a horse or equestrian service?'},
        {id: 'question_11', question: 'What kind of notifications does MSM send?'},
        {id: 'question_12', question: 'How do I turn on or off notification from MSM?'},
        {id: 'question_13', question: 'How much does it cost to advertise my horse for sale or my equestrian service?'},
        {id: 'question_14', question: 'What are the benefits of listing/advertising on MSM?'},
        {id: 'question_15', question: 'How do I delete/deactivate my profile?'},
        {id: 'question_16', question: 'How do I save a filter?'},
      ]
    }
  },
  watch: {
    selected_question(newValue) {
      $('.question').hide();
      $('#' + newValue).show();
    }
  },
  mounted() {
    document.title = 'FAQ - Mane Street Market';
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped lang="scss">
  .question {
    margin: 20px 0;
    display: none;

    h1 {
      font-size: 20px;
      margin-bottom: 24px;
    }

    p {
      font-size: 16px;
    }
  }

  iframe {
    aspect-ratio: 16/9;
    width: 100%;
  }

  .videos-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .video-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
</style>